<template>
        <div class="choice-button">
          <div
            v-for="choice in value.options"
            :key="choice.name"
            :class="['card', { 'card-checked': value.selection.includes(choice.name) }]"
          >
            <div class="check-row">
              <div @click="toggleSelection(choice.name)">
                <input
                  type="checkbox"
                  :id="choice.name"
                  :value="choice.name"
                  v-model="selectedChoice"
                  class="check-button"
                />
              </div>
              <label class="v-label" :for="choice.name">
                <img class="cb-icon" v-if="value.selection.includes(choice.name)" src="/images/cb-check.svg" />
                <img class="cb-icon" v-if="!value.selection.includes(choice.name)" src="/images/cb-uncheck.svg" />
                {{ choice.name }}
              </label>
              <div v-if="choice.total >= 0" class="increment-decrement-button">
                <button @click="decrement(choice)">-</button>
                <span>{{ choice.total }}</span>
                <button @click="increment(choice)">+</button>
              </div>
              <img :src="choice.img" 
                  v-if="choice.img"
                  :style="{}/*imageStyle(k)*/"
                  alt="no image" class="choice-image mx-auto cursor-pointer">
            </div>
          </div>
        </div>
      </template>
      
      <script>
      export default {
        name: 'CheckListButton',
        props: {
          value: { type: Object, required: true }
        },
        data() {
          return {
            selectedChoice: this.value.selection
          };
        },
        methods: {
          toggleSelection(choiceName) {
            const index = this.value.selection.indexOf(choiceName);
            if (index > -1) {
              this.value.selection.splice(index, 1);
            } else {
              this.value.selection.push(choiceName);
            }
          },
          increment(choice) {
            choice.total++;
          },
          decrement(choice) {
            if (choice.total > 0) {
              choice.total--;
            }
          }
        }
      };
      </script>
      
      <style scoped>
      .choice-button {
        display: flex;
        flex-direction: column;
      }
      
      .check-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      
      .card {
        border: 1px solid #ccc;
        margin: 5px 0;
        border-radius: 5px;
        padding: 20px;
      }
      
      .card-checked {
        border-color: #007bff;
      }
      
      .cb-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      .v-label {
        display: flex;
        align-items: center;
        padding-left: 1em;
        text-transform: uppercase;
        font-family: 'Roboto';
        font-size: 1em;
        font-style: italic;
        font-weight: 600;
      }
      
      .check-button {
        display: none;
      }
      
      
      .increment-decrement-button {
          display: flex;
          align-items: center;
          padding: 10px;
          padding-left: 30px;
        }
        
        .increment-decrement-button button {
          width: 30px;
          height: 30px;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #92cfe0;
          padding: 10px;
          color:#92cfe0;
          background-color:#ffffff;
          border-color: #92cfe0;
          border-radius: 15%;
          border-width: 2px;
        }
        
        .increment-decrement-button span {
          font-size: 20px;
          width: 30px;
          text-align: center;
          text-transform: uppercase;
          font-family: 'Roboto';
          font-size: 1em;
          font-style: italic;
          font-weight: 600;
        }
      
        .choice-image{
          height: 86px;
          width: 86px;
          display: flex;
          align-items: flex-end;
      }
      </style>