<template>
        <div class="one-finance-plan-viewer">
            <div class="rec-container rounded px-3" style="min-height:8em">
            <div class="row row-cols-2 text-center
                  recommendation py-2">
            <div class="col">
                <div class = "row" style="display:block;">    
                <p class="text-title mb-0"> Sum Assured</p>
                     <h4 class="my-text">  {{ getFinanceData.short_journey.recommended_portfolio.hi.cover / 100000 + " Lakhs" }} </h4>
                     </div>
                </div>
                <div class="col">
                    <div class = "row" style="display:block;">   
                    <p class="text-title mb-0">Policy Tenure</p>
                    <div>
                         <h4 class="my-text"> 1 Year </h4>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            <h6 class="one-finance-header-text">
                Featured list of Health Insurance Plans
            </h6>
            <span class="one-finance-text">Powered by 1Finance</span>
            <div v-for="k in limitedFinanceData" :key="k.id" class="rec-container rec-rounded px-3" style="min-height:12em">
            <div class="row text-left
                  recommendation py-3"  v-if="card.key=='term_insurance'">
                    <div class="col col-30">
                        <img class="logo-container" :src="'https://imaages-hosting-1fin.s3.ap-south-1.amazonaws.com/'+ k.image_links"/>
                    </div>
                    <div class="col col-70">
                        <div class="row">
                            <h6 class="my-text" style="width: 90%;"> {{ k.insurance_plan }} </h6>
                        </div>
                        <div class="row border-gray"></div>
                        <div class="row row-cols-3 text-left recommendation py-3">
                        <div class="col">
                            <small><p class="text-muted mb-0" style="width:70%">Product Features</p></small>
                            <h6 class="my-text"> {{ k.average_product_features }} /100</h6>
                        </div>
                        <div class="col">
                            <small><p class="text-muted mb-0" style="width:70%;margin: 10px 0;">Price</p></small>
                            <h6 class="my-text"> {{ k.average_price_scoring }} /100</h6>
                        </div>
                        <div class="col">
                            <small><p class="text-muted mb-0" style="width:70%">Claim Experience</p></small>
                            <h6 class="my-text"> {{ k.average_claim_settlement_experience }} /100 </h6>
                        </div>
                        </div>
                    </div>
                <!-- <div class="col">
                    <small><p class="text-muted mb-0 stop-break ">Annual Premium (non&#8209;smoker)</p></small>
                    <div v-if="zeroCover">
                        <h6 class="my-text"> - </h6>
                    </div>
                    <div v-else>
                        <h5 class="my-text mb-0"> {{plan.premium_ns}}*</h5>
                        <p class="">
                            <small>
                                {{plan.premium_ns_percent}}
                            </small>
                        </p>
                    </div>
                </div> -->
        
               
        
                <!-- <div class="col">
                    <small><p class="text-muted mb-0 ">Annual Premium (smoker)</p></small>
                    <div v-if="zeroCover">
                       <h6 class="my-text">-</h6>
                    </div>
                    <div v-else>
                        <h5 class="my-text mb-0"> {{plan.premium_s}}*</h5>
                        <p class="mb-1">
                            <small>
                                {{plan.premium_s_percent}}
                            </small>
                        </p>
                    </div>
                </div> -->
        
                <!-- <div class="col d-flex align-items-end">
        
                    <button class="bg-green rounded border-0 shadow-sm  p-2 px-3
                    text-left  font-weight-bold my-text text-uppercase mb-1"
                        @click="$emit('show-email-dialog')"
                    >
                        email me
                    </button>
                </div> -->
            </div>
            
            <div class="row text-left card-footer" style="border-radius: 0 0 30px 30px; padding-right: 10px;">
                        <small><p class="text-muted mb-0" >1Finance Score </p></small>
                        <h6 class="my-text"> {{ k.one_finance_scoring }} </h6><h7 class="my-text">/100</h7></div>
            </div>
        
        
        
        
            <!-- <div class="row row-cols-2 mx-1 my-2">
        
                <div v-for="(c,n) in cards" :key="c.name" class="col px-0"
                :class="(n+1)%2==0?'pl-2':''">
                    <div class="py-1 "
        
                    >
                        <div class="d-flex rounded cursor-pointer  position-relative
                        overflow-hidden text-left  my-text cover-card"
                        :class="card==c?'border-blue':'greyed'"
        
                        @click="handleCardClick(c)">
                                <div v-if="!isReportAllowed(c)"
                                class="position-absolute d-flex align-items-center
                                justify-content-center h-100 w-100 " style="background-color:#ffffff90">
                                    <p class="fas fa-lock text-pri" style="font-size:2em"></p>
                                </div>
        
                            <div class=" icon-container  "  >
                                <img :src="'/images/result/'+c.icon+'.svg'" alt="">
                            </div>
                            <div class="p-1 d-flex flex-column pl-2 ">
                                <p class="text-gray mt-auto">{{c.name}}</p>
                                <p v-if="isReportUnlocked()"
                                class="larger font-weight-bold mb-auto">{{plan[c.key]}}</p>
                                <p v-else class="text-gray mb-auto"></p>
                            </div>
                        </div>
                    </div>
                </div>
        
            </div> -->
        
            <!-- <small class="mt-auto" style="font-size:0.75em">
                    <p @click="$router.push({name:'how-it-works'})"
                     class="cursor-pointer my-link mb-0">
                    Know more about your recommendation
                    </p>
                </small> -->
         <Modal :show="showAccessReportPopUp && !isReportUnlocked()">
                <div class="d-flex rounded-extra flex-column ">
                    <div v-if="$store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString()" class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                            text-center bg-light rounded mt-3" style="padding: 20px;">
                            <h5 > You can access the report by clicking OK. Note that this report will be shared with the advisor </h5>
                            <div class="d-flex flex-row justify-content-stretch p-0 button-group" style="width: 100px;margin: 0 auto;">
                                <button type="button"
                                    class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 bg-pri"
                                    data-dismiss="modal"
                                    @click="accessReport()">
                                    OK
                                </button>
                            </div>
                            <h5 class="mt-3"> If you don't want to share the report with advisor then you can purchase it here or exit</h5>
                            <div class="d-flex flex-row justify-content-stretch p-0 button-group" style="width: 250px;margin: 0 auto;">
                                <button type="button"
                                    class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 bg-pri"
                                    @click="purchaseReport()">
                                    Purchase Report
                                </button>
                                <button type="button"
                                    class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 ml-3 bg-sec"
                                    data-dismiss="modal"
                                    @click="showAccessReportPopUp = false;">
                                    Exit
                                </button>
                            </div>
                    </div>
                  </div>
        </Modal>
        
        </div>
        
        </template>
        
        <script>
        import Modal from '../common/Modal'
        
        export default {
            props:['plan','formData'],
            components:{
                Modal,
            },
            computed:{
                limitedFinanceData() {
                   return this.getFinanceData.onefinance.slice(0, 3);
                },
                getFinanceData(){
                    return this.$store.state.oneFinanceData
                },
                zeroCover(){
                    //
                    return this.plan.coverValue == 0 || isNaN(this.plan.coverValue);
                },
                baseUrl(){
                    const isRetail = (!this.isLoggedIn && !this.isShareLinkClicked) ? true : false
                    return this.$baseUrl+(isRetail?'retail/': 'ifa/')
                },
                baseUrlRetail(){
                    return this.$baseUrl+'/retail/'
                },
            },
            methods:{
                async accessReport() {
                    this.$store.commit('isretail',false);
                    if (this.$store.state.creditLinkCredits <= 0) {
                        this.$error('Insufficient Credits !!')
                    }
                    if (window.location.href.indexOf('credit') > -1 && !this.$store.getters.allow_reports) {
                        if (!this.$store.state.user) { // Not logged In
                            if (window.location.href.indexOf('credit') > -1) {
                               this.isShareLinkClicked = true;
                            }
                            let apiRequest;
                            apiRequest = this.$axios.post(this.$base_Node_Url +'generic/generatePlanIFA' , {url: this.baseUrl+'get_recommendation/' , data: this.formData })
                            const res = await apiRequest
                            const planData = res.data.data
                            if(res){
                                this.loadingAccessReport = true;
                                this.$store.commit('setPlanData',planData)
                                this.$store.commit('setFormData',this.formData)
                                if (window.location.href.indexOf('credit') > -1 && !this.$store.getters.allow_reports) {
                                    let str = window.location.href.split('credit/')[1];
                                    let params = str.split('/');
                                    let payload = { creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard}
                                    try {
                                        let res = await this.$axios.post(this.$base_Node_Url+'credit-link/checkCreditAndUpdateCreditForPurchaseReport', payload , {headers: {'Content-Type': 'application/json'}})
                                        if (res) {
                                            this.$store.dispatch('getCreditLinkByUrl', {creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard})
                                            // setTimeout(() => {
                                                this.loadingAccessReport = false;
                                                this.showAccessReportPopUp = false;
                                                this.$store.commit('unlockReport');
                                            // },100)
                                            this.$success('Report Generated Successfully !!')
                                        }
                                    } catch(err) {
                                        this.$error(err)
                                    }
                                }
                            }
                        }
                    }
                },
                handleCardClick(card){
                    this.selectedCard=card;
                    if (this.$route.path.indexOf('credit') > -1) {
                        if (!this.$store.state.user) { // Not logged In
                            if (this.$store.state.creditLinkCredits > 0 && this.$store.state.creditValidity > new Date().toISOString()) {
                                // this.showAccessReportPopUp = true;
                                this.accessReport()
                                if (this.$store.getters.allow_reports) {
                                    this.card=card
                                }
                            } else {
                                this.card=card
                                if(!this.$store.getters.allow_reports){
                                    this.$store.dispatch('showReportUnlockPayment')
                                }
                            }
                        }
                    } else {
                        if(!this.$store.getters.allow_reports){
                            this.$store.dispatch('showReportUnlockPayment')
                        }else{
                            this.card=card
                        }
                    }
                },
                purchaseReport() {
                    if(!this.isReportAllowed(this.selectedCard) || window.location.href.indexOf('credit') > -1){
                        this.$store.dispatch('showReportUnlockPayment')
                    }
                    if(window.location.href.indexOf('credit') > -1 && this.$store.getters.allow_reports){
                        this.$store.dispatch('showReportUnlockPayment')
                    }
                },
                isReportAllowed(card){
                    const isTerm = card.key === 'term_insurance'
                    const reportsAllowed = this.$store.getters.allow_reports
                    return isTerm || reportsAllowed
                },
                isReportUnlocked() {
                    return this.$store.getters.allow_reports
                }
            },
            data(){
                const fdata = this.formData
                const retired = fdata.starting_age >= fdata.retirement_age
                const houseIncome =   fdata.annual_income_self+
                                fdata.annual_income_spouse+
                                fdata.annual_income_other
        
                const allow_reports = false //this.$store.getters.allow_reports
                const showCorpus = !retired && houseIncome > 0
        
                const term = {name:'Term Insurance',key:'term_insurance',
                        icon:'term',value:'8500',ok:true}
                const retirement = {name:'Retirement Corpus',key:'retirement_corpus',
                        icon:'retirement',value:'8500',ok:allow_reports}
                const illness = {name:'Critical Illness Cover',key:'critical_illness',
                        icon:'illness',value:'54900',ok:allow_reports}
                const medical = {name:'Medical Insurance',key:'medical_insurance',
                        icon:'health',value:'7000',ok:allow_reports}
        
                let cards  =[term,retirement,illness,medical,]
        
        
        
                const zeroCover = !(this.plan.coverValue > 0)
                const showAlt = fdata.starting_age > 50 || zeroCover
                if(allow_reports){
        
                    if(showAlt){
                        cards = [retirement,illness,term,medical,]
                    }
                    if(!showCorpus){
                        cards = [illness,medical,retirement,term]
                    }
                }
                return{
                    cards,
                    card:cards[0],
                    selectedCard: '',
                    showAccessReportPopUp: false
                }
            }
        
        }
        </script>
        
        <style>
        .rec-container {
            background-color: white;
            border: 1px solid lightblue;
            padding: 0 0;
            margin: 8px 8px;
        }
        .one-finance-header-text {
            font-family: Roboto;
            font-size: 22px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color:#2F3676;
            margin-top: 12px;
        }
        .one-finance-text {
            font-family: Roboto;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #55ADC6;
            padding: 2px;
        }
        .cover-card {
            --icbg: rgb(205, 226, 246);
            background-color: aliceblue;
            /* box-shadow: 0 0 8px lightgray; */
            border: 1px solid var(--icbg);
            cursor: pointer;
        }
        .icon-container{
        
            padding: 10px;
            background-color: var(--icbg);
        }
        .icon-container>img{
             width: 4.0em;
        }
        
        .logo-container {
             width: 5.0em;
             height: auto;
        }
        
        .cover-card.greyed{
             border : none;
             opacity: 0.7;
        }
        .cover-card.greyed > .icon-container{
            background-color: rgb(206, 212, 214);
            color: gray;
            opacity: 0.4;
        }
    
        .card-footer{
            background-color: #9fedcf;
            padding: 2px;
            border-radius: 30px;
            justify-content: flex-end;
            align-items: center;
        }
        .one-finance-plan-viewer .cover-card p{
            font-size: 1.1em;
        
        }
        .one-finance-plan-viewer h5{
            font-weight: bold;
        }
        .one-finance-plan-viewer .col{
            padding-right: 0;
            padding-left: 16px;
            font-size: 0.8em;
        }
        .one-finance-plan-viewer p  {
            font-size:1.2em;
            margin: 0;
        }
        .one-finance-plan-viewer h5{
            font-size:1.9em;
        }
        .one-finance-plan-viewer button{
            font-size: 1.6em;
        }
        .one-finance-plan-viewer small{
            font-size: 0.95em;
        }
        .one-finance-plan-viewer{
            font-size: 0.7em;
        }
        
        .one-finance-plan-viewer .larger{
            font-size: 1.3em;
        }
        .border-gray {
            border: 0.5px solid lightgray;
            height: 0.5px;
            margin-right: 8px;
            margin-left: -8px;
        }
    
        @media screen and (max-height:650px) {
            .one-finance-plan-viewer{
                font-size: 0.6em;
            }
        }
        
        .stop-break{
            word-wrap: keep-all;
        }
        p.micro-text{
            font-size: 0.9em;
            color: gray;
        
        }
        #app .greyed{
            color: gray;
            background-color: whitesmoke;
        }
        
        .border-blue{
            border: 1px solid rgb(168, 197, 224);
            box-shadow: 0 0 8px var(--icbg);
        }
        p.text-title {
            font-size: 1.8em;
            color: black;
            padding: 1px;
        }
        .col-30 {
            width: 30%;
        }
        .col-70 {
            min-width: 70%;
        }
        p.text-muted {
            font-size: 1.2em;
            color: rgb(61, 61, 61);
            padding: 3px;
        }
        h6.my-text {
            font-size: 1.7em;
            color: #2F3676;
            padding: 3px;
        }
        .rec-rounded {
            border-radius: 30px;
            -moz-border-radius: 30px; 
            -webkit-border-radius: 30px;
        }
        </style>